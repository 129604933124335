import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import DatePicker from "react-datepicker";
import { http } from '../../services/HttpInterceptor';
import { toast } from 'react-toastify';
import { observable } from 'mobx';

interface ReportProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class Report extends React.Component<ReportProps> {

    @observable private totales: any = [];
    @observable private date: Date = new Date();
    componentDidMount() {
        this.init();
    }

    init = () => {
        const newDate = `${this.date.getFullYear()}-${("0" + (this.date.getMonth() + 1)).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}`;
        http.get("/api/dashboard/report?date=" + newDate).then((response) => {
            this.totales = response.data;
        }).catch((error) => {
            toast.error("Error cargando estadisticas");
        });
    }


    setStartDate = (date: any) => {
        this.date = date;
    }

    searchReport = () => {
        const newDate = `${this.date.getFullYear()}-${("0" + (this.date.getMonth() + 1)).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}`;
        http.get("/api/dashboard/report?date=" + newDate).then((response) => {
            this.totales = response.data;
        }).catch((error) => {
            toast.error("Error cargando estadisticas");
        });
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header card-header-primary">
                                    <h4 className="card-title ">Lista de recibos</h4>
                                    <p className="card-category">Lista de recibos de contratos</p>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        Desde: <DatePicker selected={this.date} onChange={date => this.setStartDate(date)} withPortal portalId="root-portal" />
                                        <button onClick={this.searchReport} className="btn btn-info btn-sm ">
                                            <i className="material-icons">search</i> Buscar
                                            <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Pagos Recibidos</p>
                                    <h3 className="card-title">
                                        <small> pesos recibidos</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <h1>${this.totales.total_payments_suma}</h1>
                                </div>
                                <div className="card-footer">
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Pagos Realizados</p>
                                    <h3 className="card-title">
                                        <small> pesos gastados</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <h1>${this.totales.total_payments_gastos}</h1>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Contratos Cerrados</p>
                                    <h3 className="card-title">{this.totales.total_closed}
                                        <small> contratos cerrados</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Id</th>
                                                <th>Direccion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.totales.closed_contracts && this.totales.closed_contracts.response.data.map((entry: any, index: any) => {
                                                return <tr key={index}><td><Link to={`contratos/ver/${entry.id}`}>{entry.id}</Link></td><td>{entry.address}</td></tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Instalaciones</p>
                                    <h3 className="card-title">{this.totales.total_installations}
                                        <small> instalaciones</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Id</th>
                                                <th>Direccion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.totales.installations && this.totales.installations.response.data.map((entry: any, index: any) => {
                                                return <tr key={index}><td><Link to={`contratos/ver/${entry.id}`}>{entry.id}</Link></td><td>{entry.address}</td></tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Pagos</p>
                                    <h3 className="card-title">{this.totales.total_payments}
                                        <small> pagos</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Id</th>
                                                <th>Tipo</th>
                                                <th>Cantidad</th>
                                                <th>Contrato</th>
                                                <th>Recibo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.totales.payments && this.totales.payments.response.data.map((entry: any, index: any) => {
                                                return <tr key={index}><td><Link to={`pagos/ver/${entry.id}`}>{entry.id}</Link></td><td>{entry.type}</td><td>{entry.amount}</td><td>{entry.contract_id}</td><td>{entry.invoice_id}</td></tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Ordenes de soporte</p>
                                    <h3 className="card-title">{this.totales.total_support}
                                        <small> ordenes de soporte creadas</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Id</th>
                                                <th>Contrato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.totales.support_created && this.totales.support_created.response.data.map((entry: any, index: any) => {
                                                return <tr key={index}><td><Link to={`soporte/${entry.id}`}>{entry.id}</Link></td><td>{entry.contract_id}</td></tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                    <div className="card-icon">
                                        <i className="material-icons">report</i>
                                    </div>
                                    <p className="card-category">Ordenes de soporte cerradas</p>
                                    <h3 className="card-title">{this.totales.total_support_closed}
                                        <small> ordenes de soporte cerradas</small>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Id</th>
                                                <th>Contrato</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.totales.support_closed && this.totales.support_closed.response.data.map((entry: any, index: any) => {
                                                return <tr key={index}><td><Link to={`soporte/${entry.id}`}>{entry.id}</Link></td><td>{entry.contract_id}</td></tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}