import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexAntenna from './IndexAntenna';
import NewAntenna from './NewAntenna';
import EditAntenna from './EditAntenna';

interface AntennaProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Antenna extends React.Component<AntennaProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/antenas"
            render={(props) => (
              <IndexAntenna {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/antenas/nuevo"
            render={(props) => (
              <NewAntenna {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/antenas/:id"
            render={(props) => (
              <EditAntenna {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}