import React, { Fragment } from 'react';
import './App.css';
import { observable } from "mobx"
import { observer } from 'mobx-react';
import Layout from './components/site/layout/Layout';
import Spinner from './components/site/layout/Spinner';
import { loadService } from './services/LoadService';
import { ToastContainer, toast } from 'react-toastify';
import firebase from './firebase';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/dropdown.min.css';

@observer export default class App extends React.Component {
  @observable private toggled = false;

  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      messaging.onMessage((payload:any) => {
        toast.info(payload.notification.body, {
          autoClose: 5000
        });
      });
    }
  }

  private changeToggled = (action: boolean) => {
    if (action) {
      this.toggled = false;
      return;
    }
    this.toggled = !this.toggled;
  }

  render() {
    return (
      <Fragment>
        <div className={this.toggled ? "wrapper nav-open" : "wrapper"}>
          <ToastContainer />
          {loadService.isRunning() ? <Spinner /> : null}
          <Layout toggled={this.toggled} changeToggle={this.changeToggled} />
        </div>
      </Fragment>
    )
  }
}