import { formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import React from "react";

type ContractLinkButtonProps = {
    value: any,
    label: string,
    isDate?:boolean
}
export const ContractConditionalBlock = ({ value, label, isDate }: ContractLinkButtonProps) => {
    if (value) {
        return (

            <div className="row">
                <label className="col-sm-4">{label}:</label>
                <div className="col-sm-8">
                    <strong>{isDate ? formatRelative(new Date(value), new Date(), { locale: es }) : value}</strong>
                </div>
            </div>);
    }
    return null;
}