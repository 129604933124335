import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import NewInvoice from './NewInvoice';
import ViewInvoice from './ViewInvoice';
import IndexInvoice from './InvexInvoice';
import IndexPrintInvoice from './print/IndexPrint';
import EditInvoice from './EditInvoice';
import IndexCheck from './check/IndexCheck';
import IndexAssign from './assign/IndexAssign';
import CreateInvoice from './CreateInvoice';

interface InvoiceProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Invoice extends React.Component<InvoiceProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/recibos"
            render={(props) => (
              <IndexInvoice {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/nuevo"
            render={(props) => (
              <NewInvoice {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/imprimir"
            render={(props) => (
              <IndexPrintInvoice {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/revisar"
            render={(props) => (
              <IndexCheck {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/asignar"
            render={(props) => (
              <IndexAssign {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/crear/:id"
            render={(props) => (
              <CreateInvoice {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/ver/:id"
            render={(props) => (
              <ViewInvoice {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/recibos/:id"
            render={(props) => (
              <EditInvoice {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}