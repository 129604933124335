import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface NewClientProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewClient extends React.Component<NewClientProps> {

    @observable private client: any = {
        name:"",
        address_street: "",
        address_colonia: "",
        phone: ""
    };
    @observable private redirect = false;
    @observable private validationErrors:string[] = [
        "name",
        "phone"
    ];

    componentDidMount() {
        this.props.changeToggle(true);
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.client[name] = value;
    }

    private setValidation = (value:boolean, name:string):void =>{
        const error = this.validationErrors.indexOf(name);
        if(value && error === -1) {
            this.validationErrors.push(name);
        } else if(!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if(this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        this.client.created_by = sessionService.getUserInfo().id;
        // eslint-disable-next-line
        this.client.phone = this.client.phone.replace(/\-/g, '');
        
        http.post(`/api/client/create`, JSON.stringify(this.client))
            .then(() => {
                toast.success(`Cliente agregado`);
                this.redirect = true;
            }).catch((error)=>{
                if(Array.isArray(error)) {
                    error.forEach((val) =>{
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if(this.redirect) {
            return <Redirect to="/clientes" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Cliente: {this.client.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../clientes`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.client.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_street"
                                    label="Calle"
                                    value={this.client.address_street}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_colonia"
                                    label="Colonia"
                                    value={this.client.address_colonia}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="phone"
                                    label="Teléfono"
                                    value={this.client.phone}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"###-###-####"}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Crear Cliente</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}