import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexSupport from './IndexSupport';
import NewSupport from './NewSupport';
import EditSupport from './EditSupport';

interface SupportProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Support extends React.Component<SupportProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/soporte"
            render={(props) => (
              <IndexSupport {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/soporte/:id/nuevo"
            render={(props) => (
              <NewSupport {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/soporte/:id"
            render={(props) => (
              <EditSupport {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}