import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    Link,
    RouteComponentProps
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';

interface IndexAssignProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class IndexAssign extends React.Component<IndexAssignProps> {

    @observable private isLoaded = false;
    @observable private collector_id: any = "";
    @observable private collectors : any = [];
    @observable private scanResult: string = "";
    @observable private result_invoices: any = [];

    onChange = (event: any) => {
        if (event.target.name === "collectors") {
            this.collector_id = event.target.value;
        }
        if (event.target.name === "scanResult") {
            this.scanResult = event.target.value;
        }
    }

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const obj = JSON.stringify({
            claim: "Recibos"
        });
        http.post(`/api/user/read`, obj).then((response) => {
            this.collectors = response.data.response;
            this.isLoaded = true;
        })
            .catch((error) => {
                toast.error("Error cargando cobradores");
            });
    }

    private enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            if(this.collector_id === "") {
                toast.error("Debes seleccionar un cobrador");
                return;
            }
            const code = event.target.value.match(/R(\d+)\|C(\d+)/);
            if (code) {
                const invoice = {
                    id: code[1],
                    collector_id: this.collector_id
                };

                http.post(`/api/invoice/assign`, JSON.stringify(invoice))
                    .then(() => {
                        this.result_invoices.push(invoice);
                        toast.success(`Recibo asignado a cobrador`);
                    });
                this.scanResult = "";
               
            } else {
                toast.error("Codigo de barras invalido");
                this.scanResult = "";
            }
        }
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Asignar recibos</h4>
                                <p className="card-category">Asignar recibos a cobradores</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <div className="form-group bmd-form-group">
                                                <select name="collectors" onChange={this.onChange} value={this.collector_id} className="form-control custom-select">
                                                    <option value="">--Elige un cobrador para asignar</option>
                                                    {this.collectors && this.collectors.map((option: any, index: any) => {
                                                        return (
                                                            <option value={option.id} key={index}>{option.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Recibos asignados</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../recibos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" ref={input => input && input.focus()} name="scanResult" autoComplete="off" placeholder="Escanea un código de barras..." onChange={this.onChange}
                                                className="form-control" value={this.scanResult} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                </div>
                                {this.result_invoices.length > 0 &&
                                    <div className="row">
                                        <div className="table-responsive">
                                            <h3>Recibos asignados {this.result_invoices.length}</h3>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                    <tr>
                                                        <th>Recibo #</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.result_invoices.map((invoice: any, index: any) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <td>{invoice.id}</td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}