import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexInstallation from './IndexInstallation';
import ViewInstallation from './ViewInstallation';
import EditInstallation from './EditInstallation';

interface InstallationProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Installation extends React.Component<InstallationProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/instalaciones"
            render={(props) => (
              <IndexInstallation {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/instalaciones/ver/:id"
            render={(props) => (
              <ViewInstallation {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/instalaciones/:id"
            render={(props) => (
              <EditInstallation {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}