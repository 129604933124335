import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface PayAdditionalProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class PayAdditional extends React.Component<PayAdditionalProps> {

    @observable private payment: any = {
        type: "Cobrador",
        amount: "",
        invoice_id: "",
        tipo: "Adicional",
        invoice_to_pay: "",
        notes: ""
    };

    @observable private additionals:any = [];
    @observable private redirect = false;
    @observable private validationErrors: string[] = [
        "amount",
        "invoice_id"
    ];
    @observable private isLoaded: boolean = false;
    @observable private contract_invoices = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.payment.contract_id = id;
        return http.get("/api/invoice/adittionals").then((response) =>{
            this.additionals = response.data.response.data;
            this.isLoaded = true;
        }).then(()=>{
            return http.get(`/api/invoice/contract/${id}`).then((response) => {
                this.contract_invoices = response.data.response.data.filter((e:any) => e.status === "Pendiente" || e.status === "Vencido");
            });
        });
        
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.payment[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.payment.created_by = sessionService.getUserInfo().id;

        this.payment.amount = this.payment.amount.replace("$", '').replace(/,/g, '');
        
        if(this.payment.invoice_to_pay === "") {
            delete this.payment.invoice_to_pay;
        }

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/payment/create`, JSON.stringify(this.payment))
            .then(() => {
                toast.success(`Pago agregado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/pagos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Registrar un Pago</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../pagos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                        name="contract_id"
                                        label="Contrato #"
                                        value={this.payment.contract_id}
                                        setValidation={this.setValidation}
                                        required={false}
                                        onChange={this.handleChange}
                                        type="text"
                                        disabled={true}
                                />
                                <InputForm
                                    name="invoice_id"
                                    label="Recibo #"
                                    value={this.payment.invoice_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.additionals.map((additional:any) =>{
                                        return { id: additional.id, name: additional.id}
                                    })}
                                />
                                <InputForm
                                    name="amount"
                                    label="Cantidad recibida"
                                    value={this.payment.amount}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    prefix="$"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={false}
                                />
                                <InputForm
                                    name="invoice_to_pay"
                                    label="Pagar un Recibo"
                                    value={this.payment.invoice_to_pay}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.contract_invoices.map((invoice:any) =>{
                                        return { id: invoice.id, name: `Recibo: ${invoice.id} - Vence: ${invoice.due_date} - Total: ${invoice.amount} - Status: ${invoice.status}` }
                                    })}
                                />
                                <InputForm
                                    name="notes"
                                    label="Notas (Max: 255 caracteres)"
                                    value={this.payment.notes}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    maxLength={255}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Agregar Pago</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}