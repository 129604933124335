import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';

interface AddNegativeProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class AddNegative extends React.Component<AddNegativeProps> {

    @observable private contract: any;
    @observable private amount: string = "";
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [
        "due_balance"
    ];
    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;

        http.get(`/api/contract/read/${id}`).then((response) => {
            this.contract = response.data;
            if(this.contract.due_balance != 0){
                this.validationErrors = [];
            }
        })
        .then(() => {
            this.isLoaded = true;
        })
        .catch((error) => {
            toast.error("Error cargando Informacion");
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.contract[name] = value;
    }

    
    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

       const obj = {
           id: this.contract.id,
           notes_due_balance: this.contract.notes_due_balance,
           amount: this.contract.due_balance.replace("$", '').replace(/,/g, '')
       }

        http.post(`/api/contract/negative`, JSON.stringify(obj))
            .then(() => {
                toast.success(`Salgo deudor agregado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to={`/contratos/ver/${this.contract.id}`} />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Fijar saldo deudor a contrato {this.contract && this.contract.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`/contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="due_balance"
                                    label="Cantidad a agregar"
                                    value={this.contract.due_balance}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    prefix="$"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={false}
                                />
                                <InputForm
                                    name="notes_due_balance"
                                    label="Notas (Max: 255 caracteres)"
                                    value={this.contract.notes_due_balance}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    maxLength={255}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Agregar saldo deudor</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}