import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { MENU } from '../site/menu';

interface EditRoleProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditRole extends React.Component<EditRoleProps> {

    @observable private rol: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];
    @observable private claims: any = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/role/read/${id}`).then((response) => {
            this.rol = response.data;
        }).then(() => {
            MENU.forEach((menu: any) => {
                const actions: any = [];
                if (menu.actions) {
                    menu.actions.forEach((action: any) => {
                        const actionChecked = this.rol.claims.indexOf(menu.name + '.' + action) >= 0;
                        const actionforMenu = {
                            name: action,
                            value: menu.name + action,
                            checked: actionChecked
                        }
                        actions.push(actionforMenu);
                    });
                }
                const checked = this.rol.claims.indexOf(menu.name) >= 0;
                this.claims.push({ name: menu.name, value: menu.name, checked: checked, actions });
            });
            this.isLoaded = true;
        })
            .catch((error) => {
                console.log(error);
                toast.error("Error cargando rol");
            });
    }

    private handleCheck = (index: any) => {
        this.claims[index].checked = !this.claims[index].checked;
        if(this.claims[index].actions && this.claims[index].checked === false) {
            this.claims[index].actions.forEach((action:any) =>{
                action.checked = false;
            })
        }
    }

    handleActionCheck(index: any, parentIndex: any): void {
        this.claims[parentIndex].actions[index].checked = !this.claims[parentIndex].actions[index].checked;
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.rol[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.rol.claims = [];
        //build claims for request
        this.claims.forEach((claim: any) => {
            if (claim.checked === true) {
                this.rol.claims.push(claim.name);
                if (claim.actions) {
                    claim.actions.forEach((action: any) => {
                        if (action.checked === true) {
                            this.rol.claims.push(claim.name + '.' + action.name);
                        }
                    })
                }
            }
        });

        if (this.validationErrors.length > 0 || this.rol.claims.length === 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        console.log(JSON.stringify(this.rol));

        http.post(`/api/role/update`, JSON.stringify(this.rol))
            .then(() => {
                toast.success(`Rol actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/roles" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Rol: {this.rol.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../roles`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.rol.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <div className="row">
                                    <label className="col-sm-2 col-form-label label-checkbox">Permisos del rol</label>
                                    <div className="col-sm-10 checkbox-radios">
                                        {
                                            this.claims.map((claim: any, index: any) => {
                                                if (index === 0) {
                                                    return null;
                                                }
                                                return (
                                                    <Fragment key={index}>
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input" type="checkbox" checked={claim.checked} onChange={() => this.handleCheck(index)} /> <strong>{claim.value}</strong>
                                                                <span className="form-check-sign">
                                                                    <span className="check"></span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        {this.drawActions(claim.actions, index)}
                                                        <br />
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Rol</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }

    private drawActions = (actions: any, parentIndex: any) => {
        const names: any = {
            "add": "Agregar",
            "edit": "Editar",
            "view": "Ver",
            "delete": "Borrar",
            "balance": "Saldo a Favor",
            "support": "Soporte",
            "additional": "Recibos adicionales",
            "close": "Cerrar",
            "cutoff": "Fechas de Corte",
            "print": "Imprimir",
            "disconnect_charge": "Cargo de reconexion",
            "barcode": "Alta de pagos con codigo de barras",
            "types": "Tipos de sectoriales",
            "card": "Filtrar por tarjeta",
            "pay": "Pagar",
            "addinvoice": "Crear recibos de contrato"
        }
        if (actions === undefined) {
            return null;
        }
        return actions.map((action: any, index: any) => {
            return (
                <div className="form-check" key={`action-` + index}>
                    <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" checked={action.checked} onChange={() => this.handleActionCheck(index, parentIndex)} /> {names[action.name]}
                        <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
            )
        });
    }
}