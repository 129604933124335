import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { sessionService } from '../../services/SessionService';
import swal from 'sweetalert';

interface RegisterPaymentProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class RegisterPayment extends React.Component<RegisterPaymentProps> {

    @observable private validationErrors: string[] = [];
    @observable private scanResult: string = "";
    @observable private payments: any = [];

    componentDidMount() {
        this.props.changeToggle(true);
    }

    private handleChange = (event: any) => {
        const { value } = event.target;
        this.scanResult= value;
    }

    private enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            const code = event.target.value.match(/R(\d+)\|C(\d+)/);
            if (code) {
                this.scanResult = "";
                if (this.payments.filter((e: any) => e.invoice_id === code[1]).length > 0) {
                    toast.error("Código de barras ya leido");
                    return;
                }
                const payment = {
                    invoice_id: code[1],
                    contract_id: code[2],
                    result: "Ok",
                    created_by: sessionService.getUserInfo().id
                };

                http.post(`/api/payment/payInvoice`, JSON.stringify(payment))
                    .then(() => {
                        this.payments.push(payment);
                        toast.success(`Recibo marcado como pagado`);
                    }).catch((error) => {
                        if (error.error && error.error !== "Ya pagado" && error.error !== "No se encontro informacion de este recibo.") {
                            let html = `<h3>${error.error}</h3><table><tbody><tr><th style='width: 33%;'>Cantidad</th><th style='width: 33%;'>Tipo</th><th style='width: 33%;'>Fecha</th></tr>`;
                            error.data.forEach((data: any) => {
                                html += `<tr><td>$${data.amount}</td><td>${data.type}</td><td>${data.created_date}</td></tr>`;
                            });
                            html += "</tbody></table>";
                            swal({
                                title: "Confirmar acción",
                                content: {
                                    element: "div",
                                    attributes: {
                                        innerHTML: html
                                    }
                                },
                                icon: "warning",
                                dangerMode: true,
                            }).then(pressOk => {
                                if (pressOk) {
                                    toast.success("Press OK");

                                    //Aqui crear el pago
                                    const newpayment = {
                                        type: "Cobrador",
                                        amount: error.amount,
                                        invoice_id: payment.invoice_id,
                                        created_by: payment.created_by
                                    };
                                    http.post(`/api/payment/create`, JSON.stringify(newpayment))
                                    .then(() => {
                                        toast.success(`Pago agregado`);
                                    }).catch((error) => {
                                        if (Array.isArray(error)) {
                                            error.forEach((val) => {
                                                toast.error(val);
                                            })
                                        } else {
                                            toast.error(error);
                                        }
                                    });
                                }
                            });
                        }
                        toast.error(error.error);
                    });
            } else {
                toast.error("Codigo de barras invalido");
                this.scanResult = "";
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Registrar un Pago</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../pagos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" ref={input => input && input.focus()} name="scanResult" autoComplete="off" placeholder="Escanea un código de barras..." onChange={this.handleChange}
                                                className="form-control" value={this.scanResult} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                </div>
                                {this.payments.length > 0 &&
                                    <div className="row">
                                        <div className="table-responsive">
                                            <h3>Pagos registrados {this.payments.length}</h3>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                    <tr>
                                                        <th>Recibo #</th>
                                                        <th>Contrato #</th>
                                                        <th>Resultado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.payments.map((payment: any, index: any) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <td>{payment.invoice_id}</td>
                                                                        <td>{payment.contract_id}</td>
                                                                        <td>{payment.result}</td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}