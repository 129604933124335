import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface EditSectorialProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditSectorial extends React.Component<EditSectorialProps> {

    @observable private sectorial: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];
    @observable private sectorial_types = [];
    @observable private contracts: any = [];
    @observable private isTecnico = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/sectorial/read/${id}`).then((response) => {
            this.sectorial = response.data;
            this.isTecnico = sessionService.getUserInfo().role === "tecnico";
        })
            .then(() => {
                return http.get(`/api/sectorial/types`)
                    .then((response: any) => {
                        this.sectorial_types = response.data.response;
                    })
            })
            .then(() => {
                return http.get(`/api/contract/sectorial/${id}`)
                    .then((response: any) => {
                        this.contracts = response.data.response;
                        this.isLoaded = true;
                    })
            })
            .catch(() => {
                toast.error("Error cargando sectorial");
            });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.sectorial[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private validateIp = (event: any): void => {
        const regex = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
        if (regex.test(this.sectorial.ip)) {
            this.setValidation(false, "ip");
        } else {
            this.setValidation(true, "ip");
        }
    }

    private validateRanges = (event: any): void => {
        if (Number(this.sectorial.range_start) > Number(this.sectorial.range_end)) {
            this.setValidation(true, "range_start");
        } else {
            this.setValidation(false, "range_start");
        }
    }

    private formatRangeValue = (value: string): string => {
        if (Number(value) <= 1) {
            return "1";
        } else if (Number(value) > 255) {
            return "255";
        }
        return value;
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        this.sectorial.mhz = this.sectorial.mhz.replace("mhz", '');
        this.sectorial.channel = this.sectorial.channel.replace("mhz", '');
        this.sectorial.ip_end = this.sectorial.ip_end.replace(" ", '');

        http.post(`/api/sectorial/update`, JSON.stringify(this.sectorial))
            .then(() => {
                toast.success(`Sectorial actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }

        if (this.redirect) {
            return <Redirect to="/sectoriales" />
        }

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Sectorial: {this.sectorial.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../sectoriales`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.sectorial.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                    disabled={this.isTecnico}
                                />
                                <InputForm
                                    name="ip"
                                    label="IP"
                                    value={this.sectorial.ip}
                                    setValidation={this.validateIp}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                    disabled={this.isTecnico}
                                />
                                {
                                    this.validationErrors.indexOf("ip") !== -1 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label style={{ float: "right", color: "#b50000" }} className="error pull-right">La ip no coincide con los 3 primeros octetos validos</label>
                                        </div>
                                    </div>
                                }
                                <InputForm
                                    name="ip_end"
                                    label="Antena"
                                    value={this.sectorial.ip_end}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    format="###"
                                    disabled={this.isTecnico}
                                />
                                <InputForm
                                    name="range_start"
                                    label="Rango Inicio"
                                    value={this.sectorial.range_start}
                                    setValidation={this.validateRanges}
                                    customValidation={this.validateRanges}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    format={this.formatRangeValue}
                                    disabled={this.isTecnico}
                                />
                                {
                                    this.validationErrors.indexOf("range_start") !== -1 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label style={{ float: "right", color: "#b50000" }} className="error pull-right">El inicio del rango no puede ser mayor al final</label>
                                        </div>
                                    </div>
                                }
                                <InputForm
                                    name="range_end"
                                    label="Rango Final"
                                    value={this.sectorial.range_end}
                                    setValidation={this.validateRanges}
                                    customValidation={this.validateRanges}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    disabled={this.isTecnico}
                                />
                                {
                                    this.validationErrors.indexOf("range_start") !== -1 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label style={{ float: "right", color: "#b50000" }} className="error pull-right">El inicio del rango no puede ser mayor al final</label>
                                        </div>
                                    </div>
                                }
                                <InputForm
                                    name="ssid"
                                    label="SSID"
                                    value={this.sectorial.ssid}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                    disabled={this.isTecnico}
                                />
                                <InputForm
                                    name="mhz"
                                    label="MHz"
                                    value={this.sectorial.mhz}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    suffix="mhz"
                                    disabled={this.isTecnico}
                                />
                                <InputForm
                                    name="channel"
                                    label="Canal"
                                    value={this.sectorial.channel}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    suffix="mhz"
                                    disabled={this.isTecnico}
                                />
                                <InputForm
                                    name="sectorial_type_id"
                                    label="Tipo de sectorial"
                                    value={this.sectorial.sectorial_type_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.sectorial_types}
                                    disabled={this.isTecnico}
                                />
                                {!this.isTecnico &&
                                    <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Sectorial</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Contratos en esta sectorial</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Cliente</th>
                                                <th>IP</th>
                                                <th>MAC</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.contracts.map((contract: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{contract.id}</td>
                                                                <td>{contract.client}</td>
                                                                <td>{contract.ip}</td>
                                                                <td>{contract.mac}</td>
                                                                <td>{contract.status}</td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}