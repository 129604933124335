import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { observable } from 'mobx';

interface IndexCardProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexCard extends React.Component<IndexCardProps> {

    @observable private cards: any;
    @observable private isLoaded = false;
    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get('/api/card/read').then((response:any) => {
            this.cards = response.data.response;
            this.isLoaded = true;
        }).catch(() => {
            toast.error("Error cargando tarjetas");
        });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de tarjetas para pagos</h4>
                                <p className="card-category">
                                    Tarjetas para recibir pagos
                                </p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`/pagos/tarjetas/nuevo`}>
                                            <button className="btn btn-info btn-lg  pull-right">
                                                <i className="material-icons">account_circle</i> Nueva Tarjeta
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Nombre</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.cards.map((card: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{card.name}</td>
                                                                <td className="td-actions text-right">
                                                                    <Link to={`/pagos/tarjetas/${card.id}`}>
                                                                        <button style={{ margin: 5 }} type="button" className="btn btn-success">
                                                                            <i className="material-icons">edit</i>
                                                                        </button>
                                                                    </Link>

                                                                    <button onClick={() => this.deleteClient(card.id, index)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                                        <i className="material-icons">close</i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    private deleteClient = (id: any, index: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres borrar la tarjeta?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/card/delete`, deleteObject)
                        .then(() => {
                            this.cards.splice(index, 1);
                            toast.success("Tarjeta borrada correctamente");
                        }).catch((error) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Hay pagos ligados a esta tarjeta");
                            } else {
                                toast.error("Error borrando tarjeta");
                            }
                        })
                }
            });
    }
}