import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';

interface ViewCutOffProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class ViewCutOff extends React.Component<ViewCutOffProps> {

    @observable private cutoff: any;
    @observable private isLoaded = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/cutoff/read/${id}`).then((response) => {
            this.cutoff = response.data;
            this.isLoaded = true;
        })
            .catch((error) => {
                toast.error("Error cargando fecha de corte");
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Fecha de Corte {this.cutoff.date}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../../contratos/cortes/${this.cutoff.id}`}>
                                            <button className="btn btn-success btn-sm  pull-right">
                                                <i className="material-icons">edit</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="col-sm-2">
                                        <Link to={`../../../contratos/cortes`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12" style={{textAlign: "center"}}>
                                        <h2>Fecha de corte cada dia {this.cutoff.date}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-info card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">analytics</i>
                                </div>
                                <h3 className="card-title">Contratos con esta fecha de corte</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}