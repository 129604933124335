import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { observable } from 'mobx';
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { createBrowserHistory } from "history";
import Pagination from "react-js-pagination";
import { sessionService } from '../../services/SessionService';
import { apiUrl } from '../../services/HttpInterceptorUtil';

registerLocale('es', es);
setDefaultLocale('es');

interface IndexInvoiceProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

const history = createBrowserHistory({ forceRefresh: true });
@observer export default class IndexInvoice extends React.Component<IndexInvoiceProps> {

    @observable private fromDate: Date = new Date();
    @observable private toDate: Date = new Date();
    @observable private invoices: any;
    @observable private isLoaded = false;
    @observable private total: number = 0;
    @observable private page: any = 1;
    @observable private status: any = "";
    @observable private cutoff_date: any = "";
    @observable private cutoff_dates: any = [];
    @observable private type: any = "";
    @observable private searching: boolean = false;
    @observable private sortBy: any = "i.id";
    @observable private sortOrder: any = "DESC";
    @observable private search: any = "";
    @observable private statusOptions: any = [{
        name: "Pendiente", value: "Pendiente"
    }, {
        name: "Pagado", value: "Pagado"
    }, {
        name: "Pendiente Desconexion", value: "Pendiente Desconexion"
    }, {
        name: "Vencido", value: "Vencido"
    }, {
        name: "Cancelado", value: "Cancelado"
    }];

    @observable private types: any = [{
        name: "Instalacion", value: "Instalacion"
    }, {
        name: "Mensualidad", value: "Mensualidad"
    }, {
        name: "Soporte", value: "Soporte"
    }, {
        name: "Adicional", value: "Adicional"
    }, {
        name: "Antena", value: "Antena"
    }];

    onChange = (event: any) => {
        if (event.target.name === "status") {
            this.status = event.target.value;
        } else if (event.target.name === "cutoff_date") {
            this.cutoff_date = event.target.value;
        } else if (event.target.name === "type") {
            this.type = event.target.value;
        }

        const obj = JSON.stringify({
            page: 1,
            status: this.status,
            cutoff_date: this.cutoff_date,
            type: this.type,
            sort_by: this.sortBy,
            sort_order: this.sortOrder,
        });
        http.post(`/api/invoice/read`, obj).then((response) => {
            this.invoices = response.data.response.data;
            this.total = response.data.response.total;
        }).catch(() => {
            toast.error("Error cargando recibos");
        })
    }

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.post('/api/invoice/read', JSON.stringify({ page: 1, sort_by: this.sortBy, sort_order: this.sortOrder })).then((response) => {
            this.invoices = response.data.response.data;
            this.total = response.data.response.total;
        }).then(() => {
            http.get('/api/cutoff/read').then((response) => {
                this.cutoff_dates = response.data.response;
                this.isLoaded = true;
            });
        }).catch((error) => {
            toast.error("Error cargando recibos");
        });
    }

    changePage = (page: any) => {
        this.page = page;
        let obj;
        obj = JSON.stringify({
            page: this.page,
            status: this.status,
            cutoff_date: this.cutoff_date,
            from: `${this.fromDate.getFullYear()}-${("0" + (this.fromDate.getMonth() + 1)).slice(-2)}-${("0" + this.fromDate.getDate()).slice(-2)} 00:00:00`,
            to: `${this.toDate.getFullYear()}-${("0" + (this.toDate.getMonth() + 1)).slice(-2)}-${("0" + this.toDate.getDate()).slice(-2)} 23:59:59`,
            type: this.type,
            sort_by: this.sortBy,
            sort_order: this.sortOrder
        });
        http.post(`/api/invoice/read`, obj).then((response) => {
            this.invoices = response.data.response.data;
            this.total = response.data.response.total;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando contratos");
        });
    }

    searchByDate = () => {
        if (this.fromDate === null || this.toDate === null) {
            toast.error("Debes seleccionar un rango de fechas");
            return;
        }
        const obj = JSON.stringify({
            page: 1,
            status: this.status,
            cutoff_date: this.cutoff_date,
            from: `${this.fromDate.getFullYear()}-${("0" + (this.fromDate.getMonth() + 1)).slice(-2)}-${("0" + this.fromDate.getDate()).slice(-2)} 00:00:00`,
            to: `${this.toDate.getFullYear()}-${("0" + (this.toDate.getMonth() + 1)).slice(-2)}-${("0" + this.toDate.getDate()).slice(-2)} 23:59:59`,
            sort_by: this.sortBy,
            sort_order: this.sortOrder
        });
        http.post(`/api/invoice/read`, obj).then((response) => {
            this.invoices = response.data.response.data;
            this.total = response.data.response.total;
        }).catch(() => {
            toast.error("Error cargando recibos");
        })
    }

    setStartDate = (date: any, input: string) => {
        if (input === "from") {
            this.fromDate = date;
        } else {
            this.toDate = date;
        }
    }

    changeSort = (value: string) => {
        this.sortBy = value;
        this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        this.changePage(this.page);
    }

    enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            window.open(`${apiUrl().url.API_URL}/recibos/ver/${this.search}`, "_blank");
        }
    }
    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de recibos</h4>
                                <p className="card-category">Lista de recibos de contratos</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-5">
                                        {sessionService.canViewSection("Recibos.print") &&
                                            <Link to={`/recibos/revisar`}>
                                                <button className="btn btn-warning btn-md ">
                                                    <i className="material-icons">print</i> Arqueo de recibos
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                        {sessionService.canViewSection("Recibos.print") &&
                                            <Link to={`/recibos/asignar`}>
                                                <button className="btn btn-warning btn-md ">
                                                    <i className="material-icons">print</i> Asignar recibos
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                        {sessionService.canViewSection("Recibos.print") &&
                                            <Link to={`/recibos/imprimir`}>
                                                <button className="btn btn-warning btn-md">
                                                    <i className="material-icons">print</i> Imprimir en Lote
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="number" name="search" autoComplete="off" placeholder="Ir a Recibo..." onChange={(e) => { this.search = e.target.value }}
                                                className="form-control" value={this.search} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        {sessionService.canViewSection("Recibos.add") &&
                                            <Link to={`/recibos/nuevo`}>
                                                <button className="btn btn-info btn-lg  pull-right">
                                                    <i className="material-icons">account_circle</i> Nuevo Recibo
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th>
                                                    <div className="form-group bmd-form-group">
                                                        <select name="status" onChange={this.onChange} value={this.status} className="form-control custom-select">
                                                            <option value="">-Todos Estatus</option>
                                                            {this.statusOptions && this.statusOptions.map((option: any, index: any) => {
                                                                return (
                                                                    <option value={option.id} key={index}>{option.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="form-group bmd-form-group">
                                                        <select name="cutoff_date" onChange={this.onChange} value={this.cutoff_date} className="form-control custom-select">
                                                            <option value="">-Todos Cortes</option>
                                                            {this.cutoff_dates && this.cutoff_dates.map((option: any, index: any) => {
                                                                return (
                                                                    <option value={option.date} key={index}>{option.date}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="form-group bmd-form-group">
                                                        <select name="type" onChange={this.onChange} value={this.type} className="form-control custom-select">
                                                            <option value="">-Todos Tipos</option>
                                                            {this.types && this.types.map((option: any, index: any) => {
                                                                return (
                                                                    <option value={option.id} key={index}>{option.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </th>
                                                <th>Desde: <DatePicker selected={this.fromDate} onChange={date => this.setStartDate(date, "from")} /></th>
                                                <th>Hasta: <DatePicker selected={this.toDate} onChange={date => this.setStartDate(date, "to")} /></th>
                                                <th>
                                                    <button onClick={this.searchByDate} className="btn btn-info btn-sm ">
                                                        <i className="material-icons">search</i> Buscar
                                                        <div className="ripple-container"></div>
                                                    </button>
                                                </th>
                                                <th>Total: {this.total}</th>
                                            </tr>
                                            <tr>
                                                <th onClick={() => this.changeSort("i.id")} style={{ cursor: "pointer" }}>#</th>
                                                <th onClick={() => this.changeSort("i.contract_id")} style={{ cursor: "pointer" }}># Contrato</th>
                                                <th onClick={() => this.changeSort("i.status")} style={{ cursor: "pointer" }}>Status</th>
                                                <th onClick={() => this.changeSort("cutoff_date")} style={{ cursor: "pointer" }}>Fecha de Corte</th>
                                                <th onClick={() => this.changeSort("i.created_date")} style={{ cursor: "pointer" }}>Creado</th>
                                                <th onClick={() => this.changeSort("i.due_date")} style={{ cursor: "pointer" }}>Vence</th>
                                                <th onClick={() => this.changeSort("cl.name")} style={{ cursor: "pointer" }}>Nombre</th>
                                                <th onClick={() => this.changeSort("address")} style={{ cursor: "pointer" }}>Direccion</th>
                                                <th onClick={() => this.changeSort("i.type")} style={{ cursor: "pointer" }}>Tipo</th>
                                                <th onClick={() => this.changeSort("payment_type")} style={{ cursor: "pointer" }}>Pago</th>
                                                <th onClick={() => this.changeSort("i.amount")} style={{ cursor: "pointer" }}>Total</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.invoices.map((invoice: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{invoice.id}</td>
                                                                <td onClick={() => invoice.contract_id ? history.push(`/contratos/ver/${invoice.contract_id}`) : ''} style={{ cursor: "pointer" }}>{invoice.contract_id}</td>
                                                                <td>{invoice.status}</td>
                                                                <td>{invoice.cutoff_date}</td>
                                                                <td>{formatRelative(new Date(invoice.created_date), new Date(), { locale: es })}</td>
                                                                <td>{invoice.due_date ? formatRelative(new Date(invoice.due_date), new Date(), { locale: es }) : ""}</td>
                                                                <td>{invoice.name}</td>
                                                                <td>{invoice.address}</td>
                                                                <td>{invoice.type}</td>
                                                                <td>{invoice.payment_type}</td>
                                                                <td>{invoice.amount}</td>
                                                                <td className="td-actions text-right">
                                                                    {sessionService.canViewSection("Recibos.view") &&
                                                                        <Link to={`recibos/ver/${invoice.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-info">
                                                                                <i className="material-icons">visibility</i>
                                                                            </button>
                                                                        </Link>
                                                                    }
                                                                    {sessionService.canViewSection("Recibos.edit") &&
                                                                        <Link to={`recibos/${invoice.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-success">
                                                                                <i className="material-icons">edit</i>
                                                                            </button>
                                                                        </Link>
                                                                    }
                                                                    {sessionService.canViewSection("Recibos.delete") &&
                                                                        <button onClick={() => this.deleteRol(invoice.id, index)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                                            <i className="material-icons">close</i>
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    private deleteRol = (id: any, index: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres cancelar el recibo?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/invoice/delete`, deleteObject)
                        .then((response) => {
                            this.invoices.splice(index, 1);
                            toast.success("Recibo cancelado correctamente");
                        }).catch((error) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Registros ligados a este recibo");
                            } else {
                                toast.error("Error borrando recibo");
                            }
                        })
                }
            });
    }
}