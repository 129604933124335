import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../../site/layout/InputForm';

interface EditCutOffProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditCutOff extends React.Component<EditCutOffProps> {

    @observable private cutoff: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/cutoff/read/${id}`).then((response) => {
            this.cutoff = response.data;
            this.isLoaded = true;
        })
        .catch((error) => {
            toast.error("Error cargando fecha de corte");
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.cutoff[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private formatRangeValue = (value:string) :string => {
        if(Number(value) < 1) {
            return "1";
        } else if(Number(value) > 31) {
            return "31";
        }
        return value;
    }

    private onSubmit = (): void => {

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/cutoff/update`, JSON.stringify(this.cutoff))
            .then(() => {
                toast.success(`Fecha de corte actualizada`);
                this.redirect = true;
            }).catch((error) => {
                if(Array.isArray(error)) {
                    error.forEach((val) =>{
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/contratos/cortes" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Fecha de corte: {this.cutoff.date}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../cortes`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="date"
                                    label="Dia de Corte"
                                    value={this.cutoff.date}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={this.formatRangeValue}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Fecha de Corte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}