import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

const config = {
    apiKey: "AIzaSyD4YtCOm4dQBCWnJ1HN_wWK3hyB9uj0wp4",
    authDomain: "servicios-inalambricos.firebaseapp.com",
    databaseURL: "https://servicios-inalambricos.firebaseio.com",
    projectId: "servicios-inalambricos",
    storageBucket: "servicios-inalambricos.appspot.com",
    messagingSenderId: "11423411494",
    appId: "1:11423411494:web:f2fe40b697331c75fba5e7"
}

firebase.initializeApp(config);

export default firebase as any;