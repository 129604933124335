import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface NewSectorialProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewSectorial extends React.Component<NewSectorialProps> {

    @observable private sectorial: any = {
        name: "",
        ip: "",
        ip_end: "",
        range_start: "",
        range_end: "",
        ssid: "",
        mhz: "",
        channel: "",
        sectorial_type_id: ""
    };
    @observable private redirect = false;
    @observable private validationErrors:string[] = [
        "name"
    ];
    @observable private sectorial_types = [];
    @observable private isLoaded = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    private init = ():void => {
        http.get(`/api/sectorial/types`)
        .then((response:any)=>{
            this.sectorial_types = response.data.response;
            this.isLoaded = true;
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.sectorial[name] = value;
    }

    private validateIp = (event:any): void => {
        const regex = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
        if(regex.test(this.sectorial.ip)) {
            this.setValidation(false, "ip");
        } else {
            this.setValidation(true, "ip");
        }
    }

    private validateRanges = (event:any):void => {
        if(Number(this.sectorial.range_start) > Number(this.sectorial.range_end)) {
            this.setValidation(true, "range_start");
        } else {
            this.setValidation(false, "range_start");
        }
    }

    private setValidation = (value:boolean, name:string):void =>{
        const error = this.validationErrors.indexOf(name);
        if(value && error === -1) {
            this.validationErrors.push(name);
        } else if(!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.sectorial.created_by = sessionService.getUserInfo().id;

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        this.sectorial.mhz = this.sectorial.mhz.replace("mhz", '');
        this.sectorial.channel = this.sectorial.channel.replace("mhz", '');
        
        http.post(`/api/sectorial/create`, JSON.stringify(this.sectorial))
            .then(() => {
                toast.success(`Sectorial agregado`);
                this.redirect = true;
            }).catch((error)=>{
                if(Array.isArray(error)) {
                    error.forEach((val) =>{
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    private formatRangeValue = (value:string) :string => {
        if(Number(value) <= 0) {
            return "0";
        } else if(Number(value) > 255) {
            return "255";
        }
        return value;
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }

        if (this.redirect) {
            return <Redirect to="/sectoriales" />
        }

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Sectorial: {this.sectorial.name}</h4>
                                </div>
                                <div className="card-text" style={{float: "right"}}>
                                    <h4 className="card-title">Antena: {`${this.sectorial.ip}.${this.sectorial.ip_end}`} </h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../sectoriales`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.sectorial.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="ip"
                                    label="IP"
                                    value={this.sectorial.ip}
                                    setValidation={this.validateIp}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                 {
                                    this.validationErrors.indexOf("ip") !== -1 && 
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label style={{ float: "right", color: "#b50000" }} className="error pull-right">La ip no coincide con los 3 primeros octetos validos</label>
                                        </div>
                                    </div>
                                }
                                <InputForm
                                    name="ip_end"
                                    label="Antena"
                                    value={this.sectorial.ip_end}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    format={this.formatRangeValue}
                                />
                                <InputForm
                                    name="range_start"
                                    label="Rango Inicio"
                                    value={this.sectorial.range_start}
                                    setValidation={this.validateRanges}
                                    customValidation={this.validateRanges}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    format={this.formatRangeValue}
                                />
                                {
                                    this.validationErrors.indexOf("range_start") !== -1 && 
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label style={{ float: "right", color: "#b50000" }} className="error pull-right">El inicio del rango no puede ser mayor al final</label>
                                        </div>
                                    </div>
                                }
                                <InputForm
                                    name="range_end"
                                    label="Rango Final"
                                    value={this.sectorial.range_end}
                                    setValidation={this.validateRanges}
                                    customValidation={this.validateRanges}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    format={this.formatRangeValue}
                                />
                                {
                                    this.validationErrors.indexOf("range_start") !== -1 && 
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label style={{ float: "right", color: "#b50000" }} className="error pull-right">El inicio del rango no puede ser mayor al final</label>
                                        </div>
                                    </div>
                                }
                                <InputForm
                                    name="ssid"
                                    label="SSID"
                                    value={this.sectorial.ssid}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="mhz"
                                    label="MHz"
                                    value={this.sectorial.mhz}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    suffix="mhz"
                                />
                                <InputForm
                                    name="channel"
                                    label="Canal"
                                    value={this.sectorial.channel}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    suffix="mhz"
                                />
                                <InputForm
                                    name="sectorial_type_id"
                                    label="Tipo de sectorial"
                                    value={this.sectorial.sectorial_type_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.sectorial_types}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Crear Sectorial</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}