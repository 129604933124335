import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface NewContractProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewContract extends React.Component<NewContractProps> {

    @observable private contract: any = {
        client_id: "",
        package_id: "",
        reference: "",
        reference_phone: "",
        reference_relationship: "",
        other_phones: "",
        address_street: "",
        address_colonia: "",
        address_zipcode: "",
        address_reference: "",
        payment_type: "",
        house_type: "",
        collection_time: "",
        cutoff_date_id: "",
        notes_creation: ""
    };
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];
    @observable private clients: any = [];
    @observable private client: any;
    @observable private packages: any = [];
    @observable private cutoffs: any = [];
    @observable private payment_types = [
        { id: "COBRADOR", name: "Cobrador" },
        { id: "DEPOSITO", name: "Deposito" },
        { id: "TRANSFERENCIA", name: "Transferencia" }
    ]
    @observable private house_types = [
        { id: "Propia", name: "Propia" },
        { id: "Renta", name: "Renta" }
    ]

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;

        http.get(`/api/client/readAll`).then((response) => {
            this.clients = response.data.response;
            this.client = this.clients.find((element: any) => {
                return element.id === id;
            });
            if (this.client) {
                this.contract.client_id = this.client.id;
            }
        })
            .then(() => {
                return http.get(`/api/package/read`).then((response) => {
                    this.packages = response.data.response;
                })
            })
            .then(() => {
                return http.get(`/api/cutoff/read`).then((response) => {
                    this.cutoffs = response.data.response;
                    const today = new Date().getDate();
                    const nextCutOff = this.getNextCutOff(this.cutoffs, today);
                    this.contract.cutoff_date_id = nextCutOff.id;
                    this.isLoaded = true;
                })
            })
            .catch((error) => {
                toast.error("Error cargando Informacion");
            });
    }

    private getNextCutOff = (array:any, val:any) =>{
        for (var i=0; i < array.length; i++) {
            if (Number(array[i].date) > val) {
                return array[i];
            }
            if(i === array.length - 1) {
                return array[0];
            }
        }
      }

      private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.contract[name] = value;
    }

    private additionalCost = ():number => {
        const todayDate = new Date();
        const today = todayDate.getDate();
        const todayMonth = todayDate.getMonth() + 1;
        const todayYear = todayDate.getFullYear();
        const cutOffDay = this.cutoffs.filter((e:any) =>{
            return Number(e.id) === Number(this.contract.cutoff_date_id);
        });
        let days = 0;
        const fechaDeCorte = cutOffDay[0].date === "31" ? (new Date(todayYear, todayMonth-1, 0)).getDate() : cutOffDay[0].date;
        if(today > fechaDeCorte) {
            //Generamos la fecha de hoy del siguiente mes
            const dateCorte= new Date(todayYear, todayMonth, fechaDeCorte); 
            const hoySinSegundos= new Date(todayYear,todayMonth -1,today); 
            const diferenciaSegundos = dateCorte.getTime() - hoySinSegundos.getTime(); 
            // To calculate the no. of days between two dates 
            days = diferenciaSegundos / (1000 * 3600 * 24); 
            days = Math.floor(days);
            //Calcular dias entre hoy y la fecha de corte cuando es el siguiente mes
        } else { //La fecha es este mes
            days = fechaDeCorte - today;
        }

        if(days > 5) {
            const costo = this.contract.package_id !== "" ? this.packages.find((x: any) => x.id === this.contract.package_id).cost : "0";
            
            return days * (Math.floor(costo/30));
        }
        return 0;
    };
    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private getTotal = () => {
        const renta = this.contract.house_type === "Renta" ? 300 : 0;
        return Number(this.packages.find((x: any) => x.id === this.contract.package_id).cost) + 300 + this.additionalCost() + renta;
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        // eslint-disable-next-line
        this.contract.reference_phone = this.contract.reference_phone.replace(/\-/g, '');
        this.contract.created_by = sessionService.getUserInfo().id;
        this.contract.total = this.getTotal();

        http.post(`/api/contract/create`, JSON.stringify(this.contract))
            .then(() => {
                toast.success(`Contrato agregado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/contratos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Contrato nuevo {this.client && <span>para {this.client.name}</span>}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`/contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {!this.client &&
                                    <InputForm
                                        name="client_id"
                                        label="Cliente"
                                        value={this.contract.client_id}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        type="select"
                                        options={this.clients}
                                    />
                                }
                                <InputForm
                                    name="package_id"
                                    label="Paquete"
                                    value={this.contract.package_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.packages}
                                    disabled={false}
                                />
                                <InputForm
                                    name="other_phones"
                                    label="Otros teléfonos"
                                    value={this.contract.other_phones}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="reference"
                                    label="Referencia"
                                    value={this.contract.reference}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="reference_phone"
                                    label="Teléfono de Referencia"
                                    value={this.contract.reference_phone}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"###-###-####"}
                                />
                                <InputForm
                                    name="reference_relationship"
                                    label="Relación de Referencia"
                                    value={this.contract.reference_relationship}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_street"
                                    label="Dirección del Contrato"
                                    value={this.contract.address_street}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="address_colonia"
                                    label="Colonia"
                                    value={this.contract.address_colonia}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                 <InputForm
                                    name="address_zipcode"
                                    label="Codigo Postal"
                                    value={this.contract.address_zipcode}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"#####"}
                                />
                                <InputForm
                                    name="address_reference"
                                    label="Referencias del lugar"
                                    value={this.contract.address_reference}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="house_type"
                                    label="Tipo de Casa"
                                    value={this.contract.house_type}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.house_types}
                                />
                                <InputForm
                                    name="payment_type"
                                    label="Forma de Pago"
                                    value={this.contract.payment_type}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.payment_types}
                                />
                                <InputForm
                                    name="collection_time"
                                    label="Horario de cobro preferido"
                                    value={this.contract.collection_time}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="cutoff_date_id"
                                    label="Fecha de Corte"
                                    value={this.contract.cutoff_date_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.cutoffs.map((value:any)=>{
                                        return { id: value.id, name: "Dia " + value.date + " - (" + value.contracts +" contratos)"}
                                    })}
                                />
                                <InputForm
                                    name="notes_creation"
                                    label="Notas (Max: 255 caracteres)"
                                    value={this.contract.notes_creation}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    maxLength={255}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Crear contrato</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Costo de instalación</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <h3>-Cobro instalación: $300</h3>
                                {this.contract.house_type === "Renta" && <h3>-Casa de Renta: $300</h3> }
                                <h3>-Paquete {this.contract.package_id !== "" ? this.packages.find((x: any) => x.id === this.contract.package_id).cost : "$0"}</h3>
                                <h3>-Costo dias extra ${this.additionalCost()}</h3>
                                <h2 style={{textAlign:"right"}}>Total: ${this.contract.package_id !== "" ? this.getTotal() : "300"}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}