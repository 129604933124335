import React, { Fragment } from 'react';
import { observable } from "mobx"
import { observer } from 'mobx-react';
import { sessionService } from '../../../services/SessionService';
import { http } from '../../../services/HttpInterceptor';
import { toast } from 'react-toastify';

@observer export default class Layout extends React.Component {

    @observable private username: string = "";
    @observable private password: string = "";
    @observable private validationError = {
        username: false,
        password: false
    }

    handleChange = (event: any) => {
        if (event.target.name === "username") {
            this.validationError.username = false;
            this.username = event.target.value;
        } else {
            this.validationError.password = false;
            this.password = event.target.value;
        }
    }

    iniciarSesion = () => {
        let error = false;
        if(this.username === "") {
            this.validationError.username = true;
            error = true;
        }
        if(this.password === "") {
            this.validationError.password = true;
            error = true;
        }
        if(error) {
            return;
        }

        const login = JSON.stringify({
            username: this.username,
            password: this.password
        });

        http.post(`/api/user/login`, login)
        .then((response:any)=> {
            const user = response.data.response;
            if(user) {
                sessionService.fillInfoData(user);
                localStorage.setItem('token', user.token);
                toast.info(`Bienvenido ${response.data.response.name}`);
            } else {
                toast.error("Hay un error con el servidor");
            }
        }).catch((error) =>{
            if(error.message === "Invalid login information") {
                toast.error("Login incorrecto");
            }
        });
    }

    private enterPressed = (event:any) =>{
        let code = event.keyCode || event.which;
        if(code === 13) {
            this.iniciarSesion();
        } 
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                        <div className="card card-login">
                            <div className="card-header card-header-rose text-center">
                                <h4 className="card-title">Iniciar Sesión</h4>
                            </div>
                            <div className="card-body ">
                                <span className="bmd-form-group">
                                    <div className="input-group" style={{ height: "50px" }}>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="material-icons">email</i>
                                            </span>
                                        </div>
                                        <input type="text" name="username" required value={this.username} onChange={this.handleChange} className="form-control" placeholder="Usuario..." />
                                    </div>
                                    {this.validationError.username && <label style={{ float: "right", color: "#b50000" }} className="error">Debes ingresar un usuario.</label>}
                                </span>
                                <span className="bmd-form-group">
                                    <div className="input-group" style={{ height: "50px" }}>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="material-icons">lock_outline</i>
                                            </span>
                                        </div>
                                        <input onKeyPress={this.enterPressed} type="password" name="password" required value={this.password} onChange={this.handleChange} className="form-control" placeholder="Password..." /><br />
                                    </div>
                                    {this.validationError.password && <label style={{ float: "right", color: "#b50000" }}>Debes ingresar un password.</label>}
                                </span>
                            </div>
                            <div className="card-footer justify-content-center">
                                <button onClick={this.iniciarSesion} className="btn btn-primary pull-right">Iniciar Sesión<div className="ripple-container"></div></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>
            </Fragment>
        )
    }
}