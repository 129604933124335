import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface NewSupportProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewSupport extends React.Component<NewSupportProps> {

    @observable private support: any = {
        content: "",
        contract_id: ""
    };
    @observable private redirect = false;
    @observable private validationErrors:string[] = [
        "content"
    ];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    private init = () =>{
        const match = this.props.match;
        const id = (match as any).params.id;
        this.support.contract_id = id;
    }
    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.support[name] = value;
    }

    private setValidation = (value:boolean, name:string):void =>{
        const error = this.validationErrors.indexOf(name);
        if(value && error === -1) {
            this.validationErrors.push(name);
        } else if(!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.support.created_by = sessionService.getUserInfo().id;
        //build claims for request

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }
        
        http.post(`/api/support/create`, JSON.stringify(this.support))
            .then(() => {
                toast.success(`Orden de soporte agregado`);
                this.redirect = true;
            }).catch((error)=>{
                if(Array.isArray(error)) {
                    error.forEach((val) =>{
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (this.redirect) {
            return <Redirect to="/soporte" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Orden de soporte para el contracto #{this.support.contract_id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../roles`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="content"
                                    label="Descripción del problema"
                                    value={this.support.content}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="textarea"
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Agregar Orden de Soporte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}