import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';

interface EditInventoryProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditInventory extends React.Component<EditInventoryProps> {

    @observable private inventory: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/inventory/read/${id}`).then((response) => {
            this.inventory = response.data;
            this.isLoaded = true;
        })
        .catch(() => {
            toast.error("Error cargando antena");
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.inventory[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/inventory/update`, JSON.stringify(this.inventory))
            .then(() => {
                toast.success(`Inventario actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if(Array.isArray(error)) {
                    error.forEach((val) =>{
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }

        if (this.redirect) {
            return <Redirect to="/inventario" />
        }

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Antena: {this.inventory.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../antenas`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.inventory.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="quantity"
                                    label="Cantidad"
                                    value={this.inventory.quantity}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Inventario</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}