import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isAuthMissing, isAuthRequired, apiUrl } from "./HttpInterceptorUtil";
import { loadService } from "./LoadService";
import Q from "q";
import { sessionService } from "./SessionService";
import { toast } from "react-toastify";


export const http = axios.create({
    baseURL: apiUrl().url.API_URL
});

class HttpInterceptor {

    public init() {
        this.setupInterceptors();
    }

    public onHttpBeforeRequest(config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> {

        loadService.addCall();
        // This is for services that don't require a user to be logged in (The ticket and timeout cookie is not needed).
        if (isAuthMissing() && isAuthRequired(config.url)) {
            return Q.reject(new Error("logged out")) as PromiseLike<any> as Promise<any>;
        }

        if (isAuthRequired(config.url)) {
            if (localStorage.getItem('token')) {
                config.headers.Authorization = "Bearer " + localStorage.getItem('token');
            }
        }
        axios.defaults.baseURL = apiUrl().url.API_URL;
        axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

        return config;
    }

    public onHttpRequestError(error: any) {
        loadService.removeCall();
        return Q.reject(error);
    }

    public onHttpAfterResponse(response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> {
        loadService.removeCall();
        
        if(response && response.data && response.data.error) {
            if(response.data.error === "Invalid Token.") {
                localStorage.removeItem("token");
                toast.error("Tu sesión ha vencido");
                sessionService.logOut();
            }
            return Q.reject(new Error(response.data.error)) as PromiseLike<any> as Promise<any>;
        }

        return response;
    }

    public onHttpResponseError(error: any): Promise<any> {
        if(!navigator.onLine) {
            toast.error("No estas conectado a internet");
          }
        const errorItem = error;

        loadService.removeCall();

        const isLoggedOut = errorItem.message.includes("logged out");

        if(error.response.status === 503 && error.response.data && error.response.data.database) {
            return Q.reject(error.response.data.database) as PromiseLike<any> as Promise<any>;
        }

        if(error.response.status === 503 && error.response.data) {
            return Q.reject(error.response.data.error) as PromiseLike<any> as Promise<any>;
        }

        if(error.response.status === 400) {
            return Q.reject(error.response.data.error) as PromiseLike<any> as Promise<any>;
        }

        if (isLoggedOut) {
            //novoProvisioningService.userRequireLoginAgain = true;
            return Q.reject("Sesión expirada") as PromiseLike<any> as Promise<any>;
        }

        const isForbidden = errorItem.status === 403;

        if (isForbidden) {
            //novoProvisioningService.userRequireLoginAgain = true;
            return Q.reject("Sesión expirada") as PromiseLike<any> as Promise<any>;
        }

        const errorString = errorItem.data;
        return Q.reject(errorString) as PromiseLike<any> as Promise<any>;
    }


    private setupInterceptors() {
        axios.interceptors.request.use(this.onHttpBeforeRequest.bind(this), this.onHttpRequestError.bind(this));
        axios.interceptors.response.use(this.onHttpAfterResponse.bind(this), this.onHttpResponseError.bind(this));
    }
}

const clase = new HttpInterceptor();
http.interceptors.request.use(clase.onHttpBeforeRequest.bind(this), clase.onHttpRequestError.bind(this));
http.interceptors.response.use(clase.onHttpAfterResponse.bind(this), clase.onHttpResponseError.bind(this));

