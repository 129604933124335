import React from "react";
import { Fragment } from "react"

type PhotosProps = {
    photos: any,
    loadPhotos: () => any,
    apiUrl: () => any,
}
export const Photos = ({ photos, loadPhotos, apiUrl }: PhotosProps) => {
    return (
        <Fragment>
            {!photos &&
                <button onClick={loadPhotos} className="btn btn-warning btn-lg">
                    Cargar imagenes
                    <div className="ripple-container"></div>
                </button>
            }
            {photos && photos.length === 0 &&
                <h2>No hay imagenes</h2>
            }
            {photos && photos.map((photo: any, index: any) => {
                return (
                    <Fragment key={index}>
                        <div className="card">
                            <div className="card-body" style={{ textAlign: 'center' }}>
                                <a target="_blank" rel="noopener noreferrer" href={apiUrl().url.API_URL + photo}>
                                    <img src={apiUrl().url.API_URL + photo} alt="Imagen" style={{ maxWidth: 300 }} />
                                </a>
                            </div>
                        </div>
                    </Fragment>
                )
            })}
        </Fragment>);
}