import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'

interface EditUserProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditUser extends React.Component<EditUserProps> {

    @observable private user: any;
    @observable private roles: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];
    @observable private activeOptions = [
        { id: "0", name: "Activo" },
        { id: "1", name: "Desactivado" }
    ];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/user/read/${id}`).then((response) => {
            this.user = response.data;
        }).then(() => {
            http.get(`/api/role/read`).then((response) => {
                this.roles = response.data.response;
                this.isLoaded = true;
            })
        })
            .catch((error) => {
                toast.error("Error cargando usuario");
            });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.user[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }
    
    

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        delete this.user.created_by;
        // eslint-disable-next-line
        this.user.phone = this.user.phone.replace(/\-/g, '');

        http.post(`/api/user/update`, JSON.stringify(this.user))
            .then(() => {
                toast.success(`Usuario actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/usuarios" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Usuario {this.user.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <label className="col-sm-4">Usuario</label>
                                    <div className="col-sm-6">
                                        <strong>{this.user.username}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Creado el</label>
                                    <div className="col-sm-6">
                                        <strong>{formatRelative(new Date(this.user.created_date), new Date(), { locale: es })}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Creado por</label>
                                    <div className="col-sm-6">
                                        <strong>{this.user.created_by_name}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../usuarios`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.user.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="password"
                                    label="Password"
                                    value={this.user.password}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="password"
                                    minLength={6}
                                />
                                <InputForm
                                    name="phone"
                                    label="Teléfono"
                                    value={this.user.phone}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"###-###-####"}
                                />
                                <InputForm
                                    name="disabled"
                                    label="Activo"
                                    value={this.user.disabled}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.activeOptions}
                                />
                                <InputForm
                                    name="role_id"
                                    label="Rol"
                                    value={this.user.role_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.roles}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Usuario</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}