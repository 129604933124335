import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';

interface EditPaymentProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditPayment extends React.Component<EditPaymentProps> {

    @observable private payment: any;
    @observable private redirect = false;
    @observable private status = [
        { id: "Pendiente", name: "Pendiente" },
        { id: "Aprobado", name: "Aprobado" },
        { id: "Rechazado", name: "Rechazado" }
    ];
    @observable private validationErrors: string[] = [
    ];
    @observable private isLoaded: boolean = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/payment/read/${id}`).then((response) => {
            this.payment = response.data;
            this.isLoaded = true;
        })
            .catch(() => {
                toast.error("Error cargando Recibo");
            });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.payment[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/payment/update`, JSON.stringify(this.payment))
            .then(() => {
                toast.success(`Pago actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/pagos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Pago: #{this.payment.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../pagos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Cantidad</label>
                                    <div className="col-sm-8">
                                        <strong>${this.payment.amount}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Registrado por</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.created_by_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Registrado el</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.created_date}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Recibo #</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.invoice_id}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Tipo de Pago</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.type}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Direccion</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.address}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Folio</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.folio}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Tarjeta</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.card ? this.payment.card : this.payment.newcard}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Fecha de deposito</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.date}</strong>
                                    </div>
                                </div>
                                <InputForm
                                    name="status"
                                    label="Estatus del pago"
                                    value={this.payment.status}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.status}
                                    disabled={false}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Pago</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}