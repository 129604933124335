import React from "react";
import { Fragment } from "react"

type ContractActionButtonProps = {
    condition: boolean,
    onClick: () => any,
    className: string,
    label: string,
}
export const ContractActionButton = ({ condition, onClick, className, label }: ContractActionButtonProps) => {
    return (
        <Fragment>
            {condition &&
                <Fragment>
                    <button onClick={onClick}  className={`btn ${className} btn-md`}>
                        {label}
                        <div className="ripple-container"></div>
                    </button>
                    <br />
                </Fragment>
            }
        </Fragment>);
}