import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { Document, Page } from 'react-pdf';
import { apiUrl } from '../../services/HttpInterceptorUtil';
import { createBrowserHistory } from "history";
import { sessionService } from '../../services/SessionService';
import { formatRelative } from 'date-fns';
import { es } from 'date-fns/locale';

interface ViewInvoiceProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

const history = createBrowserHistory({ forceRefresh: true });

@observer export default class ViewInvoice extends React.Component<ViewInvoiceProps> {

    @observable private invoice: any;
    @observable private isLoaded = false;
    @observable private pageNum: any = 1;
    @observable private payments: any = [];
    @observable private id: any = "";
    @observable private numPages: any;
    @observable private editionHistory:any = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.id = id;
        http.get(`/api/invoice/read/${id}`).then((response) => {
            this.invoice = response.data;
        })
            .then(() => {
                http.get(`/api/payment/invoice/${id}`).then((response) => {
                    this.payments = response.data;
                    this.isLoaded = true;
                })
            })
            .then(() => {
                http.get(`/api/invoice/history/${this.invoice.id}`).then((response) => {
                    this.editionHistory = response.data.response;
                });
            })
            .catch(() => {
                toast.error("Error cargando Recibo");
            });
    }

    payInvoice = () => {
        history.push(`/pagos/nuevo/${this.invoice.id}`);
    }

    onPrint = () => {
        window.open(`${apiUrl().url.API_URL}/api/invoice/print/${this.invoice.id}`, "_blank");
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Recibo: #{this.invoice.id}</h4>
                                </div>
                                {sessionService.canViewSection("Recibos.print") && (this.invoice.type === "Mensualidad" || this.invoice.type === "Instalacion" || this.invoice.type === "Adicional" || this.invoice.type === "Antena") &&
                                    <div className="card-text" style={{ float: "right" }}>
                                        <button onClick={this.onPrint} className="btn btn-warning btn-md  pull-right">
                                            <i className="material-icons">print</i> Imprimir
                                        <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../recibos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-4" style={{ textAlign: 'right' }}>
                                        {sessionService.canViewSection("Recibos.edit") &&
                                            <Link to={`../../recibos/${this.invoice.id}`}>
                                                <button className="btn btn-success btn-sm  pull-right">
                                                    <i className="material-icons">edit</i>
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                {this.invoice.amount &&
                                    <div className="row">
                                        <label className="col-sm-4">Cantidad del recibo</label>
                                        <div className="col-sm-8">
                                            <strong>${this.invoice.amount}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.client_name &&
                                    <div className="row">
                                        <label className="col-sm-4">Cliente</label>
                                        <div className="col-sm-8">
                                            <strong>{this.invoice.client_name}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.client_phone &&
                                    <div className="row">
                                        <label className="col-sm-4">Teléfono Cliente</label>
                                        <div className="col-sm-8">
                                            <strong>{this.invoice.client_phone}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.collection_time &&
                                    <div className="row">
                                        <label className="col-sm-4">Horario de cobro</label>
                                        <div className="col-sm-8">
                                            <strong>{this.invoice.collection_time}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.contract_address_street &&
                                    <div className="row">
                                        <label className="col-sm-4">Dirección del contrato</label>
                                        <div className="col-sm-8">
                                            <strong>{this.invoice.contract_address_street} Colonia {this.invoice.contract_address_colonia}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.contract_id &&
                                    <div className="row">
                                        <label className="col-sm-4">Contrato #</label>
                                        <div className="col-sm-8">
                                            <strong>{this.invoice.contract_id}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.collector_name &&
                                    <div className="row">
                                        <label className="col-sm-4">Cobrador asignado</label>
                                        <div className="col-sm-8">
                                            <strong>{this.invoice.collector_name}</strong>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <label className="col-sm-4">Tipo de recibo </label>
                                    <div className="col-sm-8">
                                        <strong>{this.invoice.type}</strong>
                                    </div>
                                </div>
                                {this.invoice.balance > 0 &&
                                    <div className="row">
                                        <label className="col-sm-4">Saldo a favor agregado</label>
                                        <div className="col-sm-8">
                                            <strong>${this.invoice.balance}</strong>
                                        </div>
                                    </div>
                                }
                                {this.invoice.due_balance > 0 &&
                                    <div className="row">
                                        <label className="col-sm-4">Saldo deudor agregado</label>
                                        <div className="col-sm-8">
                                            <strong>${this.invoice.due_balance}</strong>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <label className="col-sm-4">Notas </label>
                                    <div className="col-sm-8">
                                        <strong>{this.invoice.notes}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Estatus </label>
                                    <div className="col-sm-8">
                                        <strong><h3>{this.invoice.status}</h3></strong>
                                    </div>
                                </div>
                                {this.invoice.status !== "Pagado" && this.invoice.status !== "Cancelado" && this.invoice.type !== 'Adicional' && sessionService.canViewSection("Recibos.pay") &&
                                    <button onClick={this.payInvoice} className="btn btn-success btn-lg">
                                        <i className="material-icons">payments</i> Pagar Recibo
                                        <div className="ripple-container"></div>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                    <div className="card">
                            <div className="card-header card-header-success card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">history</i>
                                </div>
                                <h3 className="card-title">Historial de edicion</h3>
                            </div>
                            <div className="card-body">
                                <div className="col-sm-12">
                                    {this.editionHistory && this.editionHistory.length === 0 &&
                                        <h2>No hay historial de edicion</h2>
                                    }
                                    {this.editionHistory && this.editionHistory.map((history: any, index: any) => {
                                        const action = history.action === "update" ? "edito" : "quito cargo de desconexion";
                                        return (
                                            <Fragment key={index}>
                                                <span><strong>{`${history.name} ${action} ${formatRelative(new Date(history.date), new Date(), { locale: es })}`}</strong><br /></span>
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Pagos</h4>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table className="table table-hover">
                                    <thead className="text-warning">
                                        <tr><th>#</th>
                                            <th>Tipo</th>
                                            <th>Cantidad</th>
                                            <th>Fecha</th>
                                            <th>Ver</th>
                                        </tr></thead>
                                    <tbody>
                                        {
                                            this.payments && this.payments.response.data.map((payment: any, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{payment.id}</td>
                                                        <td>{payment.type}</td>
                                                        <td>${payment.amount}</td>
                                                        <td>{payment.created_date}</td>
                                                        <td className="td-actions">
                                                            <button type="button" title="" onClick={() => this.viewPayment(payment.id)} className="btn btn-primary btn-link btn-sm">
                                                                <i className="material-icons">visibility</i>
                                                                <div className="ripple-container"></div>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.invoice.type === "Mensualidad" || this.invoice.type === "Instalacion" || this.invoice.type === "Adicional" || this.invoice.type === "Reconexion") &&
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-sm-12" style={{ display: "flex", justifyContent: "center" }}>
                                            <Document
                                                file={`${apiUrl().url.API_URL}/api/invoice/print/${this.invoice.id}`}
                                                onLoadSuccess={this.onDocumentLoadSuccess}
                                            >
                                                <Page pageNumber={this.pageNum} scale={1.5} />
                                            </Document>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Fragment >
        )
    }

    private viewPayment = (id: any) => {
        history.push(`/pagos/ver/${id}`);
    }

    private onDocumentLoadSuccess = (data: any) => {
        this.numPages = data.numPages;
    }
}