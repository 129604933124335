import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexSupportVisits from './IndexSupportVisits';
import EditSupportVisit from './EditSupportVisit';

interface SupportVisitsProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class SupportVisits extends React.Component<SupportVisitsProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/visitas"
            render={(props) => (
              <IndexSupportVisits {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/visitas/:id"
            render={(props) => (
              <EditSupportVisit {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}