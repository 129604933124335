import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    Link,
    RouteComponentProps
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import DatePicker from "react-datepicker";

interface IndexCheckProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class IndexCheck extends React.Component<IndexCheckProps> {

    @observable private fromDate: Date = new Date();
    @observable private toDate: Date = new Date();
    @observable private isLoaded = false;
    @observable private cutoff_date: any = "";
    @observable private cutoff_dates: any = [];
    @observable private type: any = "";
    @observable private payment_status: any = "";
    @observable private result_invoices: any = [];
    @observable private scanResult: string = "";

    @observable private types: any = [{
        name: "Mensualidad", value: "Mensualidad"
    }, {
        name: "Adicional", value: "Adicional"
    }, {
        name: "Antena", value: "Antena"
    }];

    @observable private status: any = [{
        name: "Pendiente", value: "Pendiente"
    }, {
        name: "Pagado", value: "Pagado"
    }, {
        name: "Vencido", value: "Vencido"
    }, {
        name: "Cancelado", value: "Cancelado"
    },];

    onChange = (event: any) => {
        if (event.target.name === "cutoff_date") {
            this.cutoff_date = event.target.value;
        } else if (event.target.name === "type") {
            this.type = event.target.value;
        } else if (event.target.name === "payment_status") {
            this.payment_status = event.target.value;
        } else if (event.target.name === "scanResult") {
            this.scanResult = event.target.value;
        }
    }

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get('/api/cutoff/read').then((response) => {
            this.cutoff_dates = response.data.response;
            this.cutoff_dates.push({
                date: "Adicional-Antena"
            })
            this.isLoaded = true;
        })
            .catch((error) => {
                toast.error("Error cargando recibos");
            });
    }

    setStartDate = (date: any, input: string) => {
        if (input === "from") {
            this.fromDate = date;
        } else {
            this.toDate = date;
        }
    }

    show = () => {
        if (this.cutoff_date === "" || this.type === "") {
            toast.error("Todos los campos son requeridos");
            return;
        }

        http.get(`/api/invoice/check/${this.type}/${this.cutoff_date}/${this.payment_status}/${this.fromDate.getFullYear()}-${("0" + (this.fromDate.getMonth() + 1)).slice(-2)}-${("0" + this.fromDate.getDate()).slice(-2)} 00:00:00/${this.toDate.getFullYear()}-${("0" + (this.toDate.getMonth() + 1)).slice(-2)}-${("0" + this.toDate.getDate()).slice(-2)} 23:59:59`).then((response) => {
            this.result_invoices = response.data.response.data;
        })
        .catch((error) => {
            toast.error("Error cargando recibos");
        });
    }

    private enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            const code = event.target.value.match(/R(\d+)\|C(\d+)/);
            if (code) {
                this.scanResult = "";
                let filter = this.result_invoices.filter((e: any) => e.id === code[1]);
                if (filter.length > 0) {
                    filter.forEach((f:any) => this.result_invoices.splice(this.result_invoices.findIndex((e:any) => e.id === f.id),1));
                    toast.success("Código de barras encontrado");
                    return;
                } else {
                    toast.error("Codigo de barras no encontrado");
                }
            } else {
                toast.error("Codigo de barras invalido");
                this.scanResult = "";
            }
        }
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card" style={{paddingBottom:208}}>
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Arqueo recibos</h4>
                                <p className="card-category">Arqueo de recibos</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <button onClick={this.show} className="btn btn-info btn-md  pull-right">
                                            <i className="material-icons">receipt</i> Mostrar Recibos
                                            <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <div className="form-group bmd-form-group">
                                                <select name="cutoff_date" onChange={this.onChange} value={this.cutoff_date} className="form-control custom-select">
                                                    <option value="">--Elige una fecha de corte</option>
                                                    {this.cutoff_dates && this.cutoff_dates.map((option: any, index: any) => {
                                                        return (
                                                            <option value={option.date} key={index}>{option.date}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <select name="type" onChange={this.onChange} value={this.type} className="form-control custom-select">
                                                <option value="">--Elige un tipo de recibo</option>
                                                {this.types && this.types.map((option: any, index: any) => {
                                                    return (
                                                        <option value={option.id} key={index}>{option.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <select name="payment_status" onChange={this.onChange} value={this.payment_status} className="form-control custom-select">
                                                <option value="">--Elige un estatus</option>
                                                {this.status && this.status.map((option: any, index: any) => {
                                                    return (
                                                        <option value={option.id} key={index}>{option.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        Desde: <DatePicker selected={this.fromDate} onChange={date => this.setStartDate(date, "from")} />
                                    </div>
                                    <div className="col-sm-6">
                                        Hasta: <DatePicker selected={this.toDate} onChange={date => this.setStartDate(date, "to")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Revisar recibo</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../recibos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" ref={input => input && input.focus()} name="scanResult" autoComplete="off" placeholder="Escanea un código de barras..." onChange={this.onChange}
                                                className="form-control" value={this.scanResult} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                </div>
                                {this.result_invoices.length > 0 &&
                                    <div className="row">
                                        <div className="table-responsive">
                                            <h3>Recibos encontrados {this.result_invoices.length}</h3>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                    <tr>
                                                        <th>Recibo #</th>
                                                        <th>Contrato #</th>
                                                        <th>Direccion</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.result_invoices.map((invoice: any, index: any) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <td>{invoice.id}</td>
                                                                        <td>{invoice.contract_id}</td>
                                                                        <td>{invoice.contract_address_street} {invoice.contract_address_colonia}</td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}