import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';
import swal from 'sweetalert';

interface NewInvoiceProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewInvoice extends React.Component<NewInvoiceProps> {

    @observable private invoice: any = {
        quantity: "0"
    };
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];
    @observable private additionals = 0;
    @observable private collector_id: any = "";
    @observable private collectors : any = [];
    @observable private isLoaded = true;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    private init = () => {
        return http.get("/api/invoice/adittionals").then((response) => {
            this.additionals = response.data.response.total;
            this.isLoaded = true;
        }).then(()=>{
            const obj = JSON.stringify({
                claim: "Recibos"
            });
            http.post(`/api/user/read`, obj).then((response) => {
                this.collectors = response.data.response;
                this.isLoaded = true;
            })
        }).catch((error) => {
            toast.error("Error cargando datos");
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.invoice[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    onChange = (event: any) => {
        if (event.target.name === "collectors") {
            this.collector_id = event.target.value;
        }
    }

    private onSubmit = (): void => {
        console.log(this.collector_id);
        if(this.collector_id === "") {
            toast.error("Debes seleccionar un cobrador");
            return;
        }

        if (this.invoice.quantity === "0") {
            toast.error("Debes ingresar un valor mayor a 0");
            return;
        }
        if((Number(this.invoice.quantity) + Number(this.additionals)) > 10) {
            toast.error("No puedes crear mas de 10 recibos, hay " + this.additionals + ' recibos pendientes');
            return;
        }

        this.invoice.created_by = sessionService.getUserInfo().id;
        this.invoice.collector_id = this.collector_id;
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        swal({
            title: "Confirmar acción",
            icon: "warning",
            dangerMode: true,
        }).then(pressOk => {
            if (pressOk) {
                http.post(`/api/invoice/create`, JSON.stringify(this.invoice))
                    .then(() => {
                        toast.success(`Recibos creados`);
                        this.redirect = true;
                    }).catch((error) => {
                        if (Array.isArray(error)) {
                            error.forEach((val) => {
                                toast.error(val);
                            })
                        } else {
                            toast.error(error);
                        }
                    });
            }
        });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/recibos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Crear recibos</h4>
                                </div>
                            </div>
                            <div className="card-body">
                            <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <div className="form-group bmd-form-group">
                                                <select name="collectors" onChange={this.onChange} value={this.collector_id} className="form-control custom-select">
                                                    <option value="">--Elige un cobrador para asignar</option>
                                                    {this.collectors && this.collectors.map((option: any, index: any) => {
                                                        return (
                                                            <option value={option.id} key={index}>{option.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../recibos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {this.additionals >= 10 && <h1>Solo pueden existir 10 recibos activos</h1>}
                                <InputForm
                                    name="quantity"
                                    label="Recibos a crear"
                                    value={this.invoice.quantity}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    decimal={0}
                                    disabled={this.additionals >= 10}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Crear Recibos</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}